<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("salessummaryreport") }}
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มใบขายส่ง
        </button> -->
      </div>
    </div>
    <!-- <div class="card-title row ms-5 me-5 mt-6">
        <input
          v-model="searchInput"
          placeholder="ค้นหาจาก หมายเลขเอกสาร / Invoice Number"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <select v-model="selectStatus" class="form-select">
          <option value="">--ทั้งหมด--</option>
          <option value="ยกเลิก">ยกเลิก</option>
          <option value="รอดำเนินการ">รอดำเนินการ</option>
          <option value="ออกบิล">ออกบิล</option>
        </select>
      </div>
      <div class="col-sm-2 text-start">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>

        <button
          @click="clear()"
          type="button"
          class="btn btn-sm btn-light-danger ms-2"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("clear") }}
        </button>
      </div>
    </div> -->
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-2" style="align-self: center">
        <a-date-picker
          class="form-control ant-date"
          :locale="locale"
          v-model:value="dateSelected"
          format="DD/MM/YYYY"
          inputReadOnly
        />
      </div>
      <div class="col-sm-3">
        <select v-model="vanSelected" class="form-select">
          <option value="">--เลือกVAN--</option>
          <option :value="i" v-for="i in vans" :key="i">
            {{ i.van_code }} | {{ i.van_name }}
          </option>
        </select>
      </div>
      <div class="col-sm-3">
        <select v-model="selectStatus" class="form-select">
          <option value="">--ทั้งหมด--</option>
          <option value="ยกเลิก">ยกเลิก</option>
          <option value="รอดำเนินการ">รอดำเนินการ</option>
          <option value="ออกบิล">ออกบิล</option>
        </select>
      </div>
      <div class="col-sm-4 mb-6">
        <!-- <button
          type="button"
          class="btn btn-sm btn-warning bi bi-truck"
          @click="prepareItems()"
        >
          ดาวน์โหลดใบจัดของ
        </button> -->

        <button
          @click="searchvanReports()"
          type="button"
          class="btn btn-sm btn-light-primary ms-2"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>

        <button
          @click="clear()"
          type="button"
          class="btn btn-sm btn-light-danger ms-2"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("clear") }}
        </button>
      </div>
      <!-- <div class="col-sm-12 col-md-12 text-end" v-if="dataItemsShow.length > 0"> -->
      <div class="col-sm-12 col-md-12 text-end">
        <button
          @click="openSaleReport()"
          type="button"
          class="btn btn-sm btn-light-success ms-2"
          style="font-size: 14px; font-weight: bold"
        >
          Download Sale Summary Report
        </button>

        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="สรุปการขาย_Van_Sale.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
      </div>
    </div>

    <div
      class="card-body"
      style="padding-top: 3px; padding-bottom: 15px"
      v-if="dataItemsShow.length > 0"
    >
      <div class="table-responsive">
        <a-table
          :dataSource="dataItemsShow"
          :columns="columns"
          :scroll="{ x: 1300 }"
        >
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>

            <template v-if="column.dataIndex === 'transaction_number'">
              {{ $t("transaction_number") }}
            </template>
            <template v-if="column.dataIndex === 'invoice_number'">
              Invoice Number
            </template>
            <template v-if="column.dataIndex === 'customerName'">
              {{ $t("buyername") }}
            </template>

            <template v-if="column.dataIndex === 'payment_by'">
              {{ $t("payby") }}
            </template>

            <template v-if="column.dataIndex === 'status'">
              {{ $t("status") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'transaction_number'">
              {{ record.transaction_number ? record.transaction_number : "" }}
            </template>
            <template v-if="column.dataIndex === 'invoice_number'">
              {{ record.invoice_number ? record.invoice_number : "" }}
            </template>

            <template v-if="column.dataIndex === 'status'">
              <!-- {{ record.status ? record.status : "" }} -->
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-danger"
                v-if="record.status == 'ยกเลิก'"
              >
                {{ record.status }}
              </button>
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-primary"
                v-if="record.status == 'รอดำเนินการ'"
              >
                {{ record.status }}
              </button>
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-dark"
                v-if="record.status == 'กำลังจัดของ'"
              >
                {{ record.status }}
              </button>
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-warning"
                v-if="record.status == 'จัดของแล้ว'"
              >
                {{ record.status }}
              </button>
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-success"
                v-if="record.status == 'ออกบิล'"
              >
                {{ record.status }}
              </button>
              <button
                type="button"
                style="color: white"
                class="btn p-2 btn-bg-info"
                v-if="record.status == 'จ่ายเงินแล้ว'"
              >
                {{ record.status }}
              </button>
              <!-- // กำลังจัดของ
// จัดของแล้ว
// ออกบิล
// จ่ายเงินแล้ว -->
              <!-- <select
                class="form-select"
                v-model="record.status"
                @change="changeStatus(record)"
              >
                <option
                  value="Pre-order"
                  :disabled="
                    record.status == 'รอจัดส่ง' ||
                    record.status == 'จ่ายเงินแล้ว'
                  "
                >
                  Pre-order
                </option>
                <option
                  value="คอนเฟิร์มออเดอร์"
                  :disabled="
                    record.status == 'รอจัดส่ง' ||
                    record.status == 'จ่ายเงินแล้ว'
                  "
                >
                  คอนเฟิร์มออเดอร์
                </option>
                <option
                  value="รอจัดส่ง"
                  :disabled="record.status == 'จ่ายเงินแล้ว'"
                >
                  รอจัดส่ง
                </option>
                <option value="จ่ายเงินแล้ว">จ่ายเงินแล้ว</option>
              </select> -->
            </template>
            <template v-if="column.dataIndex === 'reprint'">
              <div class="row">
                <div class="col-sm-3">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-eye"></i>
                  </button>
                </div>

                <div class="col-sm-3">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success me-sm-2 p-0"
                    @click="goToExportOrder(record, index)"
                  >
                    <i class="bi bi bi-files"></i>
                  </button>
                </div>
                <div class="col-sm-3">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                    @click="goToExportReceive(record, index)"
                  >
                    <i class="bi bi-printer-fill"></i>
                  </button>
                </div>

                <!-- <div class="col-sm-3">
                  <button
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      m-0
                      p-0
                    "
                    @click="handleCancelOrder(item)"
                    @mouseenter="handleHoverB()"
                    style="color: red; cursor: pointer"
                  >
                    <i class="bi bi-x-circle"></i>
                  </button>
                </div> -->
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>{{ $t("documentnumber") }}</th>
              <th>{{ $t("buyercode") }}</th>
              <th>{{ $t("buyercode") }}</th>
              <th>VAN</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("discouts") }}</th>
              <th>{{ $t("payby") }}</th>

              <th>{{ $t("net") }}</th>
              <th>{{ $t("cash") }}</th>
              <th>{{ $t("status") }}</th>
              <th style="text-align: center">{{ $t("reprint") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.point_of_sale_no ? item.point_of_sale_no : "" }}</td>
              <td>
                {{ item.customer ? item.customer.code : "" }}
              </td>
              <td>
                {{ item.customer.name }}
              </td>
              <td></td>
              <td>
                {{ setDateThLocale(item.createdAt) }}
              </td>
              <td style="text-align: right">
                {{ item.discount ? item.discount : 0 }} %
              </td>
              <td>{{ item.payment_by ? item.payment_by : "ไม่เข้า" }}</td>

              <td style="text-align: right">{{ item.final_price }} ฿</td>
              <td style="text-align: right">{{ item.cash || 0 }} ฿</td>
              <td>
                <select
                  class="form-select"
                  v-model="item.status"
                  @change="changeStatus(item)"
                >
                  <option
                    value="Pre-order"
                    :disabled="
                      item.status == 'รอจัดส่ง' || item.status == 'จ่ายเงินแล้ว'
                    "
                  >
                    Pre-order
                  </option>
                  <option
                    value="คอนเฟิร์มออเดอร์"
                    :disabled="
                      item.status == 'รอจัดส่ง' || item.status == 'จ่ายเงินแล้ว'
                    "
                  >
                    คอนเฟิร์มออเดอร์
                  </option>
                  <option
                    value="รอจัดส่ง"
                    :disabled="item.status == 'จ่ายเงินแล้ว'"
                  >
                    รอจัดส่ง
                  </option>
                  <option value="จ่ายเงินแล้ว">จ่ายเงินแล้ว</option>
                </select>
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        m-0
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-eye-fill"></i>
                    </button>
                  </div>
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        m-0
                        p-0
                      "
                    >
                      <i class="bi bi-printer-fill"></i>
                    </button>
                  </div>

                  <div class="col-sm-4">
                    <button
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        m-0
                        p-0
                      "
                      @click="handleCancelOrder(item)"
                      @mouseenter="handleHoverB()"
                      style="color: red; cursor: pointer"
                    >
                      <i class="bi bi-x-circle"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>

      <h4 class="mt-3">
        {{ $t("total") }} : <b>{{ total }}</b> บาท
      </h4>
    </div>

    <div v-if="isExportOrder">
      <pdfOrder
        orderForm="Pre Order"
        :dataExport="dataExport"
        @generatedFile="generatedFile"
      />
    </div>
    <div v-if="isExportSaleReport">
      <dailySaleReport
        :dataExport="dataExport"
        @generatedFile="generatedFile"
      />
    </div>
    <div v-if="isExportReceive">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <!-- <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    /> -->

    <DialogCancelOrder
      :dialogCancelOrder="dialogCancelOrder"
      @submitCancelOrder="submitCancelOrder"
      @closeDialogCancelOrder="closeDialogCancelOrder"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";

import transactionApi from "@/api/transaction/";
// import transactionNewsApi from "@/api/transaction/";

import whApi from "@/api/warehouse/";

import dailySaleReport from "../../components/files/dailySaleReport.vue";
import pdfOrder from "../../components/files/pdfOrder.vue";
import pdfFile from "../../components/files/invoiceFile.vue";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import DialogCancelOrder from "../components/dialogCancelOrder.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

export default {
  components: {
    DialogConfirmByPass,
    DialogCancelOrder,
    Pagination,
    Loader,
    pdfOrder,
    pdfFile,
    dailySaleReport,
  },
  setup() {
    document.title = "CHOMTHANA | สรุปขายปลีกประจำวัน";
    return {
      locale,
    };
  },
  // transaction_number
  // invoice_number
  // customerName
  // final_price
  // payment_by
  // status

  data: () => ({
    selectStatus: "",
    isExportSaleReport: false,
    isExportOrder: false,
    isExportReceive: false,
    json_fields: {
      // buyercode: {
      //   field: "customer",
      //   callback: (value) => {
      //     return `${value.code ? value.code : ""} `;
      //   },
      // },
      // buyername: {
      //   field: "customer",
      //   callback: (value) => {
      //     return `${value.name ? value.name : ""} `;
      //   },
      // },

      // date: {
      //   field: "date",
      //   callback: (value) => {
      //     return `${moment(value.createdAt).format("DD/MM/YYYY ")}`;
      //   },
      // },
      transaction_number: "transaction_number",
      invoice_number: "invoice_number",
      customerName: "customerName",
      final_price: "final_price",
      payment_by: "payment_by",
      status: "status",
      checkin: "checkin",
    },
    columns: [
      { title: "เวลา checkin", dataIndex: "checkin", width: 180 },
      {
        title: "เวลา checkout",
        dataIndex: "checkout",
        width: 180,
      },
      { title: "ใช้เวลา", dataIndex: "time", width: 180 },
      { title: "ชื่อร้านค้า", dataIndex: "store_name", width: 120 },
      { title: "ช่องทาง", dataIndex: "channel", width: 120 },
      { title: "ตามแพลนวิ่ง", dataIndex: "plan", width: 120 },
      { title: "นอกแพลนวิ่ง", dataIndex: "out_plan", width: 120 },
      { title: "ขายได้วันนี้", dataIndex: "sell_today", width: 120 },
      { title: "ยอดเดือนนี้", dataIndex: "month_balance", width: 120 },
      { title: "เฉลี่ยนย้อนหลัง 3 เดือน", dataIndex: "retrospect", width: 120 },
      { title: "หมายเหตุ", dataIndex: "note", width: 120 },
    ],

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    dialogCancelOrder: false,

    dateSelected: dayjs(new Date()),

    total: "",
    tableItems: [],
    itemApprove: {},

    hoverA: false,
    hoverB: false,

    searchInput: "",
    dateSearch: "",
    allData: [],
    dataExport: {},
    vans: [],
    vanSelected: "",
  }),

  created() {
    // this.search();
    // this.getAll();
    this.getAllVans();
  },
  watch: {
    dateSelected(val) {
      console.log("date val", val);
    },
  },

  methods: {
    openSaleReport() {
      this.dataExport.van_code = this.vanSelected.van_code;
      this.dataExport.products = this.dataItemsShow;
      console.log("this.dataExport", this.dataExport);
      this.isExportSaleReport = true;
    },
    async getAllVans() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.van.getAll(localStorage.getItem("branchId"));
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.vans = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async prepareItems() {
      this.loading = true;
      let responseData = [];
      const body = {
        companyId: localStorage.getItem("companyId"),
        selectedDate: moment(this.dateSelected.$d).format("YYYY-MM-DD"),
        vanId: this.vanSelected.id,
      };
      // console.log('date',this.dateSelected.$d);
      console.log("body", body);
      responseData = await transactionApi.transaction.getPrepareItems(
        body.companyId,
        body.selectedDate,
        body.vanId ? body.vanId : ""
      );
      console.log("responseData", responseData.data);
      if (responseData.response_status === "SUCCESS") {
        this.dataExport.products = responseData.data;
        // this.dataExport.createdAt = moment(this.dateSelected.$d).format(
        //   "DD-MM-YYYY"
        // );
        this.dataExport.van = this.vanSelected;
        // this.dataExport.final_price = 123;

        this.loadingExport = true;
        this.isExportOrder = true;
        setTimeout(() => {
          this.loadingExport = false;
          this.isExportOrder = false;
        }, 1500);
      }
      this.loading = false;
    },

    async getAll(method) {
      this.loading = true;
      let responseData = [];
      // let companyId = localStorage.getItem("companyId");
      try {
        responseData = await transactionApi.transaction.getAll(
          "Pre-order",
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      console.log({ responseData });
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseData.data;
        this.loading = false;
        this.sumTotal(this.dataItemsShow);
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async changeStatus(item) {
      this.loading = true;
      let responseData = [];
      // let companyId = localStorage.getItem("companyId");
      let data = { ...item, transaction_number: 123 };
      try {
        responseData = await transactionApi.transaction.update(item.id, data);
      } catch (error) {
        console.log(error);
      }
      console.log({ responseData });
      if (responseData.response_status === "SUCCESS") {
        // this.dataItemsShow = responseData.data;
        // this.tableItems = responseData.data;
        // this.loading = false;
        // this.sumTotal(this.dataItemsShow);
        this.getAll();
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    sumTotal(items) {
      let sum = items.reduce(
        (sum, data) => sum + parseFloat(data.final_price),
        0
      );
      sum = sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      this.total = sum;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //exportfile

    async goToExportOrder(item, index) {
      this.loading = true;
      // console.log("itemexport", item);
      console.log("this.vanId", item.vanId);
      let responseData = [];
      try {
        responseData = await transactionApi.transaction.getOne(item.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        let result = responseData.data;
        console.log("result", result);
        /////////
        this.dataExport = result;
        // this.dataExport.vanId = item.vanId;
        // this.dataExport.createdAt = moment(item.createdAt).format("DD-MM-YYYY");
        this.indexExport = index;
        this.loadingExport = true;
        this.isExportOrder = true;
        setTimeout(() => {
          this.loadingExport = false;
          this.isExportOrder = false;
        }, 1500);
        this.loading = false;
      }
    },
    async goToExportReceive(item, index) {
      Swal.fire({
        icon: "question",
        title: `กรุณาเลือกประเภทเอกสาร`,
        confirmButtonText: `พิมพ์ต้นฉบับ`,
        cancelButtonText: `พิมพ์สำเนา`,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#50CD89",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let responseData = [];
          try {
            responseData = await transactionApi.transaction.getOne(item.id);
          } catch (error) {
            console.log(error);
          }
          if (responseData.response_status === "SUCCESS") {
            let result = responseData.data;
            console.log("result", result);
            /////////
            this.dataExport = result;
            this.indexExport = index;
            this.dataExport.isCopy = false;
            this.loadingExport = true;
            this.isExportReceive = true;
            setTimeout(() => {
              this.loadingExport = false;
              this.isExportReceive = false;
            }, 1500);
          }
          this.loading = false;
        } else if (result.isDismissed) {
          this.loading = true;
          let responseData = [];
          try {
            responseData = await transactionApi.transaction.getOne(item.id);
          } catch (error) {
            console.log(error);
          }
          if (responseData.response_status === "SUCCESS") {
            let result = responseData.data;
            console.log("result", result);
            /////////
            this.dataExport = result;
            this.indexExport = index;
            this.dataExport.isCopy = true;
            this.loadingExport = true;
            this.isExportReceive = true;
            setTimeout(() => {
              this.loadingExport = false;
              this.isExportReceive = false;
            }, 1500);
          }
          this.loading = false;
        }
      });
      // console.log("itemexport", item);
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push("/sale/SA2-1/new");
    },
    goToEdit(id) {
      let routeData = this.$router.resolve({
        name: "sale-daily-retail-summary-preorder-edit",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "CUSTOMER-list-route",
      //   query: {
      //     id: id,
      //   },
      // });
    },
    goToDelete() {},

    handleHoverA() {
      this.hoverA = true;
      this.hoverB = false;
    },
    handleHoverB() {
      this.hoverB = true;
      this.hoverA = false;
    },

    handleApprove() {
      this.dialogConfirmByPass = true;
    },

    handleCancelOrder(item) {
      this.itemApprove = { ...item };
      this.dialogCancelOrder = true;
    },

    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },

    submitCancelOrder(isApprove) {
      this.statusApprove(isApprove, "cancel");
      this.dialogCancelOrder = false;
    },

    closeDialogCancelOrder() {
      this.dialogCancelOrder = false;
    },

    formatDate(date) {
      const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY");
      const newTime = moment(date).format("LTS");
      if (date) {
        const oldDate = `${newDate} - ${newTime}`;

        return oldDate;
      } else {
        return "-";
      }
    },

    async statusApprove(isApprove, method) {
      let updateResponse = [];
      // const id = this.itemApprove.id;

      // this.itemApprove = {
      //   ...this.itemApprove,
      //   status: isApprove ? 1 : 2,
      //   is_cancel: method === "cancel" ? "1" : "0",
      // };

      // updateResponse = await saleApi.pos.delete(id, this.itemApprove);

      // console.log(this.itemApprove);
      updateResponse = await transactionApi.transaction.delete(id);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          // this.updateProduct();
          Swal.fire({
            icon: `${method === "cancel" ? "error" : "success"}`,
            title: `${
              method === "cancel" ? "ยกเลิกรายการสำเร็จ" : "อนุมัติสำเร็จ"
            }`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.loadingUpdate = false;
            // this.search();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        // this.search();
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct() {
      let countArr = 0;
      for (const element of this.itemApprove.pointOfSaleItems) {
        countArr += 1;
        await this.getOneProduct(element.product.id, element.item_amt);
      }
      // if (
      //   countArr === this.form.products.length &&
      //   this.statusAllProcess === 1
      // ) {
      //   this.$router.push({
      //     path: "/sale/SA5",
      //     query: {},
      //   });
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) + parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    setDateThLocale(dateVal) {
      moment.locale("th");
      return moment(dateVal).format("DD/MM/YYYY - HH:mm:ss น.");
    },

    async search() {
      const newDate = this.dateSelected
        ? moment(this.dateSelected.$d).format("YYYY-MM-DD")
        : "";

      this.loading = true;

      const responseSearch = await transactionApi.transaction.search({
        search: this.searchInput,
        date: newDate,
        companyId: localStorage.getItem("companyId"),
        status: this.selectStatus,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
        this.sumTotal(this.dataItemsShow);
      }
    },
    clear() {
      this.dateSelected = dayjs(new Date());
      this.vanSelected = "";
      this.dataItemsShow = [];
    },
    async searchvanReports() {
      if (this.vanSelected) {
        this.loading = true;
        let responseData = [];
        let body = {
          selectedDate: moment(this.dateSelected.$d).format("YYYY-MM-DD"),
          vanId: this.vanSelected.id,
          status: this.selectStatus,
        };
        responseData = await transactionApi.transaction.vanReports(
          body.selectedDate,
          body.vanId ? body.vanId : "",
          body.status
        );
        if (responseData.response_status === "SUCCESS") {
          this.dataItemsShow = responseData.data;
          this.dataItemsShow.forEach((element, index) => {
            element.no = index + 1;
          });
          this.loading = false;
          this.sumTotal(this.dataItemsShow);
        }
        this.loading = false;
      } else {
        Swal.fire({
          icon: "error",
          html: `กรุณาเลือก Van !`,
          timer: 4000,
        });
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
